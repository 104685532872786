import { WistiaPlayer } from '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import { CrossTimeAurora } from './CrossTimeAurora.ts';

type BetweenTimeBinding = {
  callback: () => void;
  timeUpdateBinding: () => void;
};

class BetweenTimes {
  public readonly embedElement: WistiaPlayer;

  private readonly unbindFunction: () => void;

  readonly #bindings: Record<string, BetweenTimeBinding[] | undefined> = {};

  readonly #crossTime: CrossTimeAurora;

  public constructor(embedElement: WistiaPlayer, unbindFunction: () => void) {
    this.embedElement = embedElement;

    this.unbindFunction = unbindFunction;

    // TODO: This will be refactored to be more generic than just CrossTimeAurora
    this.#crossTime = new CrossTimeAurora(embedElement, unbindFunction);
  }

  public addBinding(startTime: number, endTime: number, callbackFn: () => void): void {
    // since this function is essentially called from the outside via end users, we need to validate the input
    // regardless of typescript argument types
    if (!/^(\d+\.)?\d+$/.test(startTime as unknown as string)) {
      throw new Error('betweenTimes: Expected first argument to be a number');
    }

    if (!/^(\d+\.)?\d+$/.test(endTime as unknown as string)) {
      throw new Error('betweenTimes: Expected second argument to be number');
    }

    if (typeof callbackFn !== 'function') {
      throw new Error('betweenTimes: Expected third argument to be a function');
    }

    const key = this.#getKeyForTimes(startTime, endTime);

    let withinInterval = false;

    const onTimeUpdate = () => {
      const time = this.embedElement.currentTime;

      if (startTime <= time && time < endTime && !withinInterval) {
        withinInterval = true;
        const result = callbackFn.call(this.unbindFunction, withinInterval) as unknown;

        if (result === this.unbindFunction) {
          this.removeBinding(startTime, endTime, callbackFn);
        }
      } else if (!(startTime <= time && time < endTime) && withinInterval) {
        withinInterval = false;
        const result = callbackFn.call(this.unbindFunction, withinInterval) as unknown;

        if (result === this.unbindFunction) {
          this.removeBinding(startTime, endTime, callbackFn);
        }
      }
    };

    this.#crossTime.addBinding(startTime, onTimeUpdate);
    this.#crossTime.addBinding(endTime, onTimeUpdate);
    this.embedElement.addEventListener('time-update', onTimeUpdate);

    onTimeUpdate();

    // if an entry doesn't exist for this time, create an array at this "slow"
    // Use an array to store multiple callback bindings at the same time
    if (!this.#bindings[key]) {
      this.#bindings[key] = [];
    }

    this.#bindings[key].push({ callback: callbackFn, timeUpdateBinding: onTimeUpdate });
  }

  public removeBinding(startTime: number, endTime: number, callbackFn: () => void): void {
    const key = this.#getKeyForTimes(startTime, endTime);
    const bindingsBetweenTimes = this.#bindings[key];
    const indexesToRemove: number[] = [];

    if (!bindingsBetweenTimes) {
      return;
    }

    bindingsBetweenTimes.forEach((binding, index) => {
      if (binding.callback === callbackFn) {
        indexesToRemove.push(index);
        this.embedElement.removeEventListener('time-update', binding.timeUpdateBinding);
        this.#crossTime.removeBinding(startTime, binding.timeUpdateBinding);
        this.#crossTime.removeBinding(endTime, binding.timeUpdateBinding);
      }
    });

    // remove the bindings entirely
    indexesToRemove.forEach((index: number) => {
      bindingsBetweenTimes.splice(index, 1);
    });
  }

  #getKeyForTimes(startTime: number, endTime: number): string {
    return `${startTime}-${endTime}`;
  }
}

export { BetweenTimes };
