import { WistiaPlayer } from '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import type { PublicApi } from '../types/player-api-types.ts';

type CrossTimeBinding = {
  callback: () => void;
  clearAnimationFrame: () => void;
  timeChangeBinding: () => void;
};

abstract class CrossTime {
  private readonly unbindFunction: () => void;

  readonly #bindings: Record<number, CrossTimeBinding[] | undefined> = {};

  public abstract embedElement: NonNullable<PublicApi['container']> | WistiaPlayer;

  public constructor(unbindFunction: () => void) {
    this.unbindFunction = unbindFunction;
  }

  public abstract get currentTime(): number;

  public abstract get state(): string;

  public addBinding(crossTime: number, callbackFn: () => void): void {
    // since this function is essentially called from the outside via end users, we need to validate the input
    // regardless of typescript argument types
    if (!/^(\d+\.)?\d+$/.test(crossTime as unknown as string)) {
      throw new Error('crosstime: Expected first argument to be a number');
    }
    if (typeof callbackFn !== 'function') {
      throw new Error('crosstime: Expected second argument to be a function');
    }

    let hasCrossedTime = this.currentTime > crossTime;
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let animationFrameId: number | undefined;

    const checkForCrossTime = () => {
      // if we've already got a recursive animation frame loop going, no need to start another one
      if (animationFrameId !== undefined) {
        return;
      }

      // a recursive animation frame to check if we've passed the time and execute the callback once we have
      // will self-terminate once the time has been crossed
      const loopAnimationFrame = () => {
        animationFrameId = requestAnimationFrame(() => {
          // only start looping if we're under 2 seconds from the crosstime so that we get
          // an almost frame accurate execution of the callback
          if (
            this.currentTime < crossTime &&
            crossTime - this.currentTime < 2 &&
            this.state === 'playing'
          ) {
            loopAnimationFrame();
          } else if (this.currentTime > crossTime) {
            // this is to match legacy behavior where the callback is called with the api as the context
            const result = callbackFn.call(this.unbindFunction) as unknown;

            // this is match legacy behavior where the callback can return the api to unbind itself
            if (result === this.unbindFunction) {
              this.removeBinding(crossTime, callbackFn);
            }

            hasCrossedTime = true;
            animationFrameId = undefined; // clear the animation frame id, so if we restart the loop we don't eject early
          } else {
            cancelAnimationFrame(animationFrameId as unknown as number);
            animationFrameId = undefined;
          }
        });
      };

      // check to see if we've cross, and if we're close, start the loop
      loopAnimationFrame();
    };
    const timeChangeBinding = () => {
      if (this.currentTime < crossTime) {
        hasCrossedTime = false;
      }
      if (!hasCrossedTime) {
        checkForCrossTime();
      }
    };

    this.embedElement.addEventListener('time-update', timeChangeBinding);

    // event binds to kill the animation frame if the video isn't progressing
    const clearAnimationFrame = () => {
      if (animationFrameId !== undefined) {
        cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = undefined;
    };

    this.embedElement.addEventListener('pause', clearAnimationFrame);
    this.embedElement.addEventListener('end', clearAnimationFrame);
    this.embedElement.addEventListener('waiting', clearAnimationFrame);

    // if an entry doesn't exist for this time, create an array at this "slow"
    // Use an array to store multiple callback bindings at the same time
    if (!this.#bindings[crossTime]) {
      this.#bindings[crossTime] = [];
    }

    // push the binding to the array. Store the given callback and our own event callbacks so we can
    // safely remove them
    this.#bindings[crossTime].push({
      callback: callbackFn,
      clearAnimationFrame,
      timeChangeBinding,
    });
  }

  public removeBinding(crossTime: number, callbackFn: () => void): void {
    const bindingsAtTime = this.#bindings[crossTime];
    const indexesToRemove: number[] = [];

    if (!bindingsAtTime) {
      return;
    }

    bindingsAtTime.forEach((binding, index) => {
      if (binding.callback === callbackFn) {
        indexesToRemove.push(index);
        // clear any animation frames that are running and destroy the event listeners
        binding.clearAnimationFrame();
        this.embedElement.removeEventListener('time-update', binding.timeChangeBinding);
        this.embedElement.addEventListener('pause', binding.clearAnimationFrame);
        this.embedElement.addEventListener('end', binding.clearAnimationFrame);
        this.embedElement.addEventListener('waiting', binding.clearAnimationFrame);
      }
    });

    // remove the bindings entirely
    indexesToRemove.forEach((index: number) => {
      bindingsAtTime.splice(index, 1);
    });
  }
}

export { CrossTime };
