import { Wistia } from '../wistia_namespace.ts';
import type { WistiaGlobal, WQCallback, WQConfigEntry } from '../types/player-api-types.ts';

const QUEUE_INTERVAL = 500;

let wistiaQueue = null as unknown as NodeJS.Timeout | null;

/**
 * Periodically checks the Wistia queue and executes the functions in it
 * @returns {void}
 */
export const maybeStartWistiaQueue = (): void => {
  if (wistiaQueue) {
    return;
  }

  wistiaQueue = setInterval(() => {
    const queue = window._wq;
    if (!queue || queue.length === 0) {
      return;
    }

    queue.slice(0).forEach((item, index) => {
      if (typeof item === 'function') {
        item(Wistia as unknown as WistiaGlobal);
        queue.splice(queue.indexOf(item), 1);
      } else if (!Wistia.flushInitQueue) {
        queue.splice(queue.indexOf(item), 1);
        // eslint-disable-next-line no-console
        console.warn(
          `<wistia-player> elements must be configured via window.wistiaOptions instead of window._wq. Config not applied`,
          item,
        );
      }
    });
  }, QUEUE_INTERVAL);
};

/**
 * Stops the Wistia queue
 * @returns {void}
 */
export const stopWistiaQueue = (): void => {
  if (wistiaQueue) {
    clearInterval(wistiaQueue);
    wistiaQueue = null;
  }
};
