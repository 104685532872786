import { isNil, isNonEmptyRecord } from '@wistia/type-guards';
// @ts-expect-error-next-line trackingConsentApi is not yet converted to TS
import { isVisitorTrackingEnabled } from 'utilities/trackingConsentApi.js';

type ProductType = 'carousel' | 'channel' | 'form' | 'other' | 'player' | 'playlist' | 'transcript';

const getSampleRatesByProductType = (productType: ProductType) => {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  switch (productType) {
    case 'carousel':
    case 'playlist':
      return 1;
    case 'channel':
    case 'form':
    case 'transcript':
      return 0.25;
    default:
      // TODO - Initially setting a low sample rate here as a precaution against blowing up Sentry with player errors
      // Ideally we can increase this once we have confidence the player errors aren't coming through in the non-player
      // error logging.
      return 0.0001;
  }
  /* eslint-enable @typescript-eslint/no-magic-numbers */
};

const IS_DEV_ENV = process.env.NODE_ENV === 'development';
const IS_TEST_ENV = process.env.NODE_ENV === 'test';

const configureSentry = () => {
  if (isNil(window.Sentry)) {
    return;
  }
  window.Sentry.init({
    allowUrls: [/^https:\/\/fast.wistia.(?:com|net|io|st)\/.*$/], // Only allow exceptions that are captured from scripts that are served by Wistia
    dsn: 'https://a3591ba5e949a37083cc6f5a4191e903@o4505518331658240.ingest.us.sentry.io/4505794284290048',
    initialScope: {
      tags: {
        pillar: 'publish',
      },
    },
    defaultIntegrations: false,
    // We want different sample rates for errors from the player versus errors from forms, channels, and transcripts.
    // So we don't configure sampleRate here and instead have custom sampling logic in the reportError function.
  });
};

export const initializeSentry = (): void => {
  window.sentryOnLoad = () => configureSentry();
  // trackingConsentApi is not yet converted to TS
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (isVisitorTrackingEnabled() !== true) {
    return;
  }

  if (!window.Sentry) {
    const sentryLoader = document.createElement('script');
    sentryLoader.src = 'https://js.sentry-cdn.com/a3591ba5e949a37083cc6f5a4191e903.min.js';
    sentryLoader.crossOrigin = 'anonymous';
    /**
     * data-lazy="no" indicates that the loader script will request the Sentry SDK
     * as part of page load, but after all of the other JavaScript on the page has run.
     * See https://docs.sentry.io/platforms/javascript/install/loader/#load-timing
     */
    sentryLoader.setAttribute('data-lazy', 'no');
    document.head.appendChild(sentryLoader);
  }
};

export const reportError = (
  product: ProductType,
  error: Error,
  details?: Record<string, string>,
): void => {
  try {
    if (typeof window.Sentry?.withScope === 'function') {
      // For now do nothing, but we should try to initialize sentry here, maybe
    }

    const sampleRate = getSampleRatesByProductType(product);

    if (IS_DEV_ENV) {
      console.error(error); // eslint-disable-line no-console
      return;
    }
    if (IS_TEST_ENV) {
      // Do nothing
      return;
    }

    let shouldSendToSentry = false;

    const cryptoObj = isNil(window.crypto) ? window.msCrypto : window.crypto;
    if (cryptoObj !== undefined) {
      const cryptoRandom = cryptoObj.getRandomValues(new Uint32Array(1));
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      shouldSendToSentry = cryptoRandom[0] / (0xffffffff + 1) < sampleRate;
    } else {
      const mathRandom = Math.random();
      shouldSendToSentry = mathRandom < sampleRate;
    }

    if (!shouldSendToSentry) {
      // If we shouldn't send to Sentry, just print to the console
      console.error(error); // eslint-disable-line no-console
      // trackingConsentApi is not yet converted to TS
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-call
    } else if (isVisitorTrackingEnabled()) {
      window.Sentry?.withScope((scope) => {
        scope.setTag('product', product);
        if (isNonEmptyRecord(details)) {
          scope.setTags(details);
        }
        window.Sentry?.captureException(error);
      });
    }
  } catch (err) {
    // We don't want any problems with our error logging to break other things,
    // so we'll just print this to the console.
    console.error(err); // eslint-disable-line no-console
  }
};
